(function ($) {
    if ('fonts' in document) {
        document.fonts.ready.then(function () {
            $('html').addClass('wf-active');
        });
    }
    else {
        $('html').addClass('wf-inactive');
    }

    setTimeout(function () {
        if ($('html').hasClass('wf-active')) return;

        $('html').addClass('wf-inactive');
    }, 1000);
})(jQuery);
