(function ($) {
    // Contact form
    $('.js-auto-select').each(function () {
        var currentPage = wp_ajax.currentPage;
        $(this).find('.gfield_select option[value="' + currentPage + '"]').attr('selected', true);
    });

    // Courses
    var timeoutId = 0;
    var trigger = $('.js-quantity-change');
    var interval = 300;

    $.each(trigger, function(){
        $(this).on('mousedown', function () {
            timeoutId = setInterval(triggerQuantityChange.bind(null, $(this)), interval);
        }).on('mouseup mouseleave', function () {
            clearInterval(timeoutId);
        });

        $(this).on('click', function () {
            triggerQuantityChange($(this));
        });
    });

    $(window).on('load', function () {
        updateCourse();
    });

    $('#input_course').on('change', function () {
        updateCourse();
    });
	
	$('#booking_type').on('change', function(){
		updateCourse();
	});
	
    function updatePrice(coursePrice, quantityInputValue) {
        var totalPrice = coursePrice * quantityInputValue;
        $('.js-total-price').text(totalPrice);
        $('input.js-total-price').val(totalPrice);
        $('input.individual-price').val(coursePrice);
    }

    function updateCourse() {
        var quantityInputSelector = $('#input_quantity').parent().find('.qty');
        var quantityInputValue = parseInt(quantityInputSelector.val());
        var selectedCourse = $('#input_course option:selected');
        var coursePrice = selectedCourse.data('price');
		var payType = $('input[name="input_course_pay_type"]');
		
		var courseNonresPrice = selectedCourse.data('nonres-price');
		var courseFullPrice = selectedCourse.data('full-price');
		
		
		if($('#booking_type').length){
			payType.val($('#booking_type option:selected').val());
		
			if(payType.val() == 'allinc') {
				coursePrice = selectedCourse.data('full-price');
			} else if (payType.val() == 'nonres') {
				coursePrice = selectedCourse.data('nonres-price');
			}
		}
		else
		{
			if(payType == 'allinc') {
				coursePrice = selectedCourse.data('full-price');
			} else if (payType == 'nonres') {
				coursePrice = selectedCourse.data('nonres-price');
			}
		}
		
        updatePrice(coursePrice, quantityInputValue);
		
		//////
		if(selectedCourse.data('full-price') == '0')
		{
			$('input.js-total-full-price').val(courseFullPrice);//for deposits
		}
		else
		{
			$('input.js-total-full-price').val(courseNonresPrice);//for deposits
		}
		
		var coursesfid = selectedCourse.data('sfid');
		$('input.js-sfid').val(coursesfid);//SF id
		var coursesname = selectedCourse.data('name');
		$('input.js-name').val(coursesname);//SF name
		
		$("#input_quantity").attr("max", selectedCourse.data("spaces"));		
    }
	
	function triggerQuantityChange(element) {
 		var quantityInputSelector = element.parent().find('.qty');
		var quantityInputValue = parseInt(quantityInputSelector.val());
		
        var quantityMinValue = parseInt(quantityInputSelector.attr('min'));
        var quantityMaxValue = parseInt(quantityInputSelector.attr('max'));
       // var selectedCourse = element.parents('.standard-form').find('#input_course option:selected');
       // var coursePrice = selectedCourse.data('price');
        var changeStatus = false;

        if (isNaN(quantityMaxValue)) {
            quantityMaxValue = 99;
        }

        if (element.hasClass('quantity__minus')) {
            quantityInputValue -= 1;
            if (quantityInputValue >= quantityMinValue) {
                quantityInputSelector.val(quantityInputValue);
                changeStatus = true;
            }

        } else if (element.hasClass('quantity__plus')) {
            quantityInputValue += 1;
            if (quantityInputValue <= quantityMaxValue) {
                quantityInputSelector.val(quantityInputValue);
                changeStatus = true;
            }
        }

        if (changeStatus) {
			// updatePrice(coursePrice, quantityInputValue);
            updateCourse();
        }
    }
	

    $('.basket-table__row .quantity__minus').click(function(){
		$('#input_quantity_'+$(this).data('target')).val(parseInt($('#input_quantity_'+$(this).data('target')).val()) - 1);
	});

	$('.basket-table__row .quantity__plus').click(function(){
		$('#input_quantity_'+$(this).data('target')).val(parseInt($('#input_quantity_'+$(this).data('target')).val()) + 1);
	});

	$('.basket-table__row .remove-basket').click(function() {
		$('#input_quantity_'+$(this).data('target')).val(0);
		$('.basket__table').submit();
	});
	
	$('#input_terms').on('change', function(){
		$('.checkbox').removeClass('checkbox-required');
	});


    if ($('.js-book-now').length > 0) {
        var form = $('.js-book-now');
        form.on('submit', function (e) {
            e.preventDefault();
			
			if($("#input_terms").prop("checked") == false) {
				$('.checkbox').addClass('checkbox-required');
			}
			
			if($("#input_terms").prop("checked") == true){
				//submit the faux gravity form via ajax to a special function
				form.find('.loading-overlay').css('display', 'flex');

				formData = form.serializeArray();
				formDataOb = {};
				jQuery.each(formData, function (i, v) {
					formDataOb[v.name] = v.value;
				});

				ajaxData = {
					'action': 'uksa_add_to_basket',
					'data': {
						'price': formDataOb.input_course_price_single,
						'qty': formDataOb.input_quantity,
						'date': formDataOb.input_course_date,
						'course_id': formDataOb.input_course_id,
						'deposit': formDataOb.input_course_deposit,
						'fullprice': formDataOb.input_course_full_price,
						'sfid': formDataOb.input_course_sfid,
						'name': formDataOb.input_course_name,
						'ptype': formDataOb.input_course_pay_type
					}
				};

				jQuery.ajax({
					method: "POST",
					url: '/wp-admin/admin-ajax.php',
					data: ajaxData
				}).done(function (response) {
					var data = JSON.parse(response);
					jQuery('.c-course-form .success-message').css('opacity', '1');
					form.find('.loading-overlay').css('display', 'none');
				});
			}            
        });
    }
	
	$('.course-table__js-cell').click(function(){
		$('#input_course').val($(this).data('course')).change();
		$('#booking_type').val($(this).data('booking')).change();
	})
	
})(jQuery);