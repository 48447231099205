require('owl.carousel');

(function ($) {
    $('.js-full-width-carousel').owlCarousel({
        dots: true,
        items: 1,
        loop: true,
        mouseDrag: true,
        nav: true,
        touchDrag: true, 
		autoplay: true,
		autoplayTimeout: 10000,
		autoplayHoverPause: true
    });

    $('.js-split-content-carousel').owlCarousel({
        dots: true,
        items: 1,
        loop: true,
        mouseDrag: true,
        nav: true,
        touchDrag: true
    });

    $('.js-card-carousel:not(.js-card-carousel__noloop)').owlCarousel({
        dots: false,
        items: 4,
        loop: true,
        mouseDrag: false,
        nav: true,
        touchDrag: false,
        responsive: {
            0: {
                items: 1
            },
            679: {
                items: 2
            },
            1025: {
                items: 4
            }
        }
    });

    $('.js-card-carousel__noloop').owlCarousel({
        dots: false,
        items: 4,
        loop: false,
        mouseDrag: false,
        nav: true,
        touchDrag: false,
        responsive: {
            0: {
                items: 1
            },
            679: {
                items: 2
            },
            1025: {
                items: 4
            }
        }
    });

    $('.js-course-intro-carousel').owlCarousel({
        dots: true,
        items: 1,
        loop: true,
		merge: true,
        mouseDrag: false,
        nav: true,
        touchDrag: false,
		video: true,
		lazyLoad:true,
		onTranslate: function() {
			var currentSlide, player, command;
			currentSlide = $('.owl-item.active');
			player = currentSlide.find(".video-item iframe").get(0);
			command = {
				"event": "command",
				"func": "pauseVideo"
			};
			if (player != undefined) {
				player.contentWindow.postMessage(JSON.stringify(command), "*");

			}
		}
    });

    $('.js-sidebar-carousel').owlCarousel({
        dots: true,
        items: 1,
        loop: true,
        mouseDrag: false,
        nav: true,
        touchDrag: false
    });

    $('.js-inline-carousel').owlCarousel({
        dots: true,
        items: 1,
        loop: true,
        nav: true,
    });
}(jQuery));