(function ($) {
    var animateDuration = 500;

    $('.js-scroll-to').on('click', function (e) {
        e.preventDefault();
        var scrollTarget = $(this).attr('data-target');

        if (!scrollTarget) return;
        scrollToSection(scrollTarget);

        if (scrollTarget.indexOf('accordion_')) {
            $(scrollTarget + ':not(.accordion--active)').find('.js-accordion-toggle').trigger('click');
        }
    });

    $('.js-dropdown-scroll-to').on('change', function (e) {
        e.preventDefault();
        var scrollTarget = $(this).find('option:selected').attr('data-target');

        if (!scrollTarget) return;
        scrollToSection(scrollTarget);

        if (scrollTarget.indexOf('accordion_')) {
            $(scrollTarget + ':not(.accordion--active)').find('.js-accordion-toggle').trigger('click');
        }
    });

    function scrollToSection(scrollTarget) {
        if (scrollTarget.length > 0) {
            var scrollPosition = $(scrollTarget).offset().top - $(".site-header").outerHeight();

            $('html, body').animate({
                scrollTop: scrollPosition
            }, animateDuration);
        }
    }
})(jQuery);
