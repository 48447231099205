(function($){	
    
    /* parallax scroll for social and testimonial elements */
	if($('.testimonial-block').length){
		var paraElement = $('.testimonial-block');
	} else if($('.social-panel').length){
		var paraElement = $('.social-panel');
    }    
    
    if(paraElement){
        var viewHeight = $(window).height();	
        var divPos = paraElement.offset().top;
        var divHeight = paraElement.height();	
        var scrollTop = 0;	
        var yPos = 50;

        $(document).on('scroll', function(){			
            var scrollDirection =  $(this).scrollTop();			
            if($(document).scrollTop() >= divPos - viewHeight && $(document).scrollTop() <= divPos + divHeight){			
                if(scrollDirection > scrollTop){
                    yPos = yPos - 0.3;
                } else {
                    yPos = yPos + 0.3;
                }			
                paraElement.css('background-position-y', yPos + '%');	
                scrollTop = scrollDirection;
            }		
        });
    } 
	
	/* postcode lookup */
	
	/* this is going to need to be triggered multiple times 
		for each participant */

/*	var fields = [
		{ element: "search", field: "" },
		{ element: "line1", field: "Line1", mode: pca.fieldMode.POPULATE },
		{ element: "line2", field: "Line2", mode: pca.fieldMode.POPULATE },
		{ element: "city", field: "City", mode: pca.fieldMode.POPULATE },
		{ element: "state", field: "Province", mode: pca.fieldMode.POPULATE },
		{ element: "postcode_input", field: "PostalCode", mode: pca.fieldMode.SEARCH }
	];
	*/

//	var options = [
		/* { key: "AA11-AA11-AA11-AA11" } */
//	];

	/* on click of postcode lookup??? */
//	var control = new pca.Address(fields, options);
	
	
})(jQuery);