
//require('./modules/headroom');
//require('./modules/jQuery-headroom');
require('./modules/accordion');
require('./modules/dropdown-select');
require('./modules/forms');
require('./modules/hero-banner');
require('./modules/main-navigation');
require('./modules/map');
require('./modules/owl-carousel');
require('./modules/scroll-to');
require('./modules/webfont-loader');
require('./modules/zextra');

//document.getElementsByClassName('no-js')[0].classList.remove('no-js');
