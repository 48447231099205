(function ($) {
    // var activeMenuClass = 'active',
    //     activeMenuSelector = '.main-navigation .active',
    //     mainNavigation = $('.main-navigation');
    //
    // mainNavigation.on('click', '.js-trigger-menu', function (e) {
    //     e.preventDefault();
    //
    //     $(this).toggleClass('active');
    //     mainNavigation.toggleClass('active');
    //
    //     if ($(activeMenuSelector).length) {
    //         $('.js-trigger-menu').not($(this)).removeClass(activeMenuClass);
    //         mainNavigation.addClass('active');
    //
    //         return;
    //     }
    //
    //     mainNavigation.removeClass('active');
    // });
    //
    // $('.sub-navigation .menu-item').on('click', 'a', function (e) {
    //     e.stopPropagation();
    // });
    //
    // $('.sub-menu .menu-item').on('click', 'a', function (e) {
    //     e.stopPropagation();
    // });
    //
    // $('html').on('click', function (e) {
    //     if (!$(e.target).closest('.menu-item').length &&
    //         ($('.js-trigger-menu').hasClass('active'))) {
    //         $('.js-trigger-menu').removeClass('active');
    //         $('.main-navigation').removeClass('active');
    //     }
    // });

    // Mobile menu
    $('.js-navigation-toggle').on('click', function (e) {
        e.preventDefault();

        // Reset the scroll position of the menu
        $('.site-header__menus').scrollTop(0);

        $('.js-navigation-target').toggleClass('site-header--open');
        $('body').toggleClass('u-lock-scrolling');
    });
	
	$('.search-toggle').on('click', function(e){
		e.preventDefault();
		$('.mobile-search-dropdown').toggleClass('open-search');	
	});

	$('.menu-item-has-children a .menu-button').on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
        
		$(this).parent().parent().toggleClass("menu-default");
		
		var findMenu = $(this).parent().parent().find("ul").first().not(".menu-item--image");
				
		if(findMenu.is(':visible')){
			$(this).text("+");			
			findMenu.hide();
		} else {
			findMenu.show();
			$(this).text("-");
		}
		
		
	});	
})(jQuery);
