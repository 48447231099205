(function ($) {
    var animateDuration = 500;

    $('.js-scroll-down').on('click', function (e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $(this).offset().top
        }, animateDuration);
    });
})(jQuery);
